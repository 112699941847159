<template>
    <div class="top-navbar">
        <div class="d-flex justify-content-center justify-content-md-start justify-content-md-end flex-wrap mt-1 mt-md-0" style="gap: 1rem;">
            <rounded-button class="filters-button" :icon="true" :iconLeft="true" :paddingIcon="1" padding="11px 20px" style="height: 46px; right: 0;" @click="showFilters">
                <template v-slot:icon><img class="mr-3" :src="require('@/assets/svg/filters.svg')"/></template>
                <template v-slot:name><div style="color: black !important;">Filtriraj / išči</div></template>
            </rounded-button>
            <rounded-button v-if="$route.path.includes('oglasi') && loggedIn" :icon="true" padding="5px 20px" style="height: 46px;" @click="$router.push({name: 'my-ads'}); $scrollToTop()">
                <template v-slot:name>VSI MOJI OGLASI</template>
                <template v-slot:icon><img class="ml-1" style="margin-right: 5px;" :src="require('@/assets/svg/Circle_Arrow_Blue.svg')"/></template>
            </rounded-button>
            <rounded-button v-if="$route.path.includes('oglasi') && loggedIn" :icon="true" padding="5px 20px" style="height: 46px;" @click="$router.push({name: 'add-ad'}); $scrollToTop()">
                <template v-slot:name>OBJAVA OGLASA</template>
                <template v-slot:icon><img class="ml-1" style="margin-right: 5px;" :src="require('@/assets/svg/circle-plus.svg')"/></template>
            </rounded-button>
        </div>
        <div v-if="show" class="mt-1 filter-content">
            <div class="p-1 d-flex align-items-center" style="background: #72A5D8;">
                <div class="title">
                    Filtrirati
                </div>
                <rounded-button class="remove-filters ml-2" padding="11px 20px" @click="removeFilters">
                    odstrani vse filtre
                </rounded-button>
            </div>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" md="6" xl="4">
                        <b-row align-h="between">
                            <b-col cols="6" sm="12" lg="6" xl="4">
                                <b-input-group class="input-group-merge" append="€">
                                    <b-form-input step="0.01" type="number" :value="sliderMin" v-on:input="changeMin" @input="searchTimeOut" placeholder="0.00"/>
                                </b-input-group>
                            </b-col>
                            <b-col cols="6" sm="12" lg="6" xl="4" class="mt-sm-1 mt-lg-0">
                                <b-input-group class="input-group-merge" append="€">
                                    <b-form-input step="0.01" type="number" :value="sliderMax" v-on:input="changeMax" @input="searchTimeOut" placeholder="100.00"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <vue-slider
                            class="mx-1 mt-2"
                            :enable-cross="false"
                            ref="slider"
                            :min="slider.min"
                            :max="slider.max"
                            v-model="slider.value"
                            tooltip="always"
                            :tooltip-formatter=" v => (v / 100).toFixed(2)"
                            @drag-start="onSliderDragStart"
                            @drag-end="onSliderDragEnd"
                            @change="onSliderChange"
                            :interval="slider.interval"
                        />

                        <b-row class="mt-2">
                            <b-col v-if="$route.path.includes('oglasi')" cols="6" sm="12" md="6" class="mt-1">
                                <radio-field class="mt-1" v-for="item of listingTypes" :key="'type_'+item.id" name="type" v-model="filter.listingType" :value="item.id" :text="item.title"/>
                            </b-col>
                            <b-col v-if="$route.path.includes('oglasi')" cols="6" sm="12" md="6" class="mt-1">
                                <radio-field class="mt-1" name="condition" v-model="filter.condition" :value="item.id" :text="item.title" v-for="item of conditions" :key="'condition_'+item.id"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" xl="8" class="mt-1 mt-sm-0 pl-xl-5">
                        <app-collapse v-if="$route.path.includes('oglasi')" :accordion="true" class="filter-collapse" :style="[windowWidth > 768 ? {'min-height': categoriesColumnsHeight + 'px'}:{'min-height': categoriesColumnHeight + 'px'}]">
                            <b-row>
                                <b-col cols="12" md="6" xl="5" v-for="(col, index) in columns" :key="'col'+index">
                                    <app-collapse-item v-for="item in col" :key="item.id" :title="item.title" :headerCSS="checkActive(item) ? 'font-weight:bold !important;':''" :isVisible="checkActive(item) ? true : false">
                                        <div v-for="child in item.children" :key="child.id">
                                            <a @click="onSelect('category', child)" :style="[checkChild(child) ? {'font-weight': 'bold'}:{}]">{{child.title}}</a>
                                        </div>
                                    </app-collapse-item>
                                </b-col>
                            </b-row>
                        </app-collapse>
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <rounded-button padding="5px 20px" style="height: 46px; width: 100%; max-width: 300px;" @click="removeFilters">
                                Ponastavi
                            </rounded-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    import RoundedButton from '@/views/Components/RoundedButton'
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'
    import {BRow, BCol, BFormInput, BInputGroup} from 'bootstrap-vue'
    import RadioField from '@/views/Components/RadioField'
    import AppCollapse from '@core/components/app-collapse/AppCollapse'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
    export default {
        components: {
            RadioField,
            RoundedButton,
            VueSlider,
            BRow,
            BCol,
            BFormInput,
            BInputGroup,
            AppCollapse,
            AppCollapseItem
        },
        props: {
            slider: {
                type: Object,
                default() {
                    return {
                        value: [0, 100],
                        min: 0,
                        max: 100,
                        interval: 1
                    }
                }
            },
            categories: {
                type: Array
            }
        },
        computed:{
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            }
        },
        data() {
            return {
                show: false,
                hasSliderDragStarted: false,
                awaitingInput: false,
                sliderMinInput: 0,
                sliderMaxInput: 0,
                firstSliderLoad: true,
                changeSlider: false,
                sliderMin: 0,
                sliderMax: 0,
                listingTypes: [
                    {id: 0, title: 'PRODAM'},
                    {id: 1, title: 'KUPIM'},
                    {id: 2, title: 'ZAMENJAM'},
                    {id: 3, title: 'ODDAM'},
                    {id: 4, title: 'PODARIM'}
                ],
                conditions: [
                    {id: 0, title: 'NOVO'},
                    {id: 1, title: 'RABLJENO'},
                    {id: 2, title: 'POŠKODOVANO'}
                ],
                filter: {
                    listingType: '',
                    condition: '',
                    categoryId: ''
                },
                categoriesColumnHeight: 0,
                categoriesColumnsHeight : 0,
                columns: [],
                windowWidth: window.innerWidth
            }
        },
        methods: {
            showFilters() {
                if (this.show) this.show = false
                else this.show = true
            },
            checkActive(item) {
                if (this.$route.path.includes('oglasi')) {
                    if (item.children.some(x => x.id === this.filter.categoryId)) return true

                } else if (item.children.some(x => x.bbis_id === this.filter.categoryId)) return true
                return false
            },
            checkChild(child) {
                if (this.$route.path.includes('oglasi')) {
                    if (child.id === this.filter.categoryId) return true

                } else if (child.bbis_id === this.filter.categoryId) return true
                return false
            },
            changeMin(val) {
                this.sliderMin = val
                this.sliderMinInput = Number(Number(val * 100).toFixed(0))
            },
            changeMax(val) {
                this.sliderMax = val
                this.sliderMaxInput = Number(Number(val * 100).toFixed(0))
            },
            onSliderDragEnd() {
                this.hasSliderDragStarted = false
                this.changeSlider = true
                this.emitSliderValues()
            },
            onSliderDragStart() {
                this.hasSliderDragStarted = true
            },
            onSliderChange() {
                // when dragging slider, @change event is firing for each changes value,
                // since I only want to emit selected value when drag stops, I listen for dragEnd
                // event and then emit values
                // not emitting only in @drag-end but also in @change, because user can click on
                // slider line, which only emits @change event, but not @drag-end

                if (!this.hasSliderDragStarted) {
                    this.emitSliderValues()
                }
            },
            emitSliderValues() {
                this.$emit('on-slider-change', {
                    value_from: this.slider.value[0],
                    value_to: this.slider.value[1]
                })
            },
            searchTimeOut() {
                if (this.awaitingInput) {
                    clearTimeout(this.awaitingInput)
                    this.awaitingInput = true
                }
                this.awaitingInput = setTimeout(() => {

                    if (this.sliderMinInput === this.sliderMaxInput) {
                        return
                    }

                    // if field is empty, don't do anything
                    //if (!this.sliderMinInput || !this.sliderMaxInput) return
                    if (this.changeSlider || this.sliderMinInput === null || this.sliderMinInput === undefined || this.sliderMaxInput === null || this.sliderMaxInput === undefined) {
                        // it must be, otherwise it comes to NavigationDuplicated
                        this.changeSlider = false
                        return
                    }

                    // if written min value is less than min, set it to min, same for max
                    if (this.sliderMinInput < this.slider.min) this.sliderMinInput = this.slider.min
                    if (this.sliderMaxInput > this.slider.max) this.sliderMaxInput = this.slider.max

                    // if for some reason min is more than max, swap values
                    if (this.sliderMinInput > this.sliderMaxInput) {
                        const tmp = this.sliderMinInput
                        this.sliderMinInput = this.sliderMaxInput
                        this.sliderMaxInput = tmp
                    }

                    this.slider.value = [this.sliderMinInput, this.sliderMaxInput]

                    this.emitSliderValues()
                }, 800)
            },
            loadValues() {
                // on first load set min and max values
                if (this.firstSliderLoad) {
                    if (isNaN(this.slider.value[0])) this.slider.value[0] = this.slider.min
                    if (isNaN(this.slider.value[1])) this.slider.value[1] = this.slider.max

                    this.sliderMinInput = this.slider.value[0]
                    this.sliderMaxInput = this.slider.value[1]

                    this.sliderMin = (this.slider.value[0] / 100).toFixed(2)
                    this.sliderMax = (this.slider.value[1] / 100).toFixed(2)
                }
            },
            onSelect(name, val) {
                if (name === 'category') {
                    if (this.$route.path.includes('oglasi')) this.filter.categoryId = val.id
                    else this.filter.categoryId = val.bbis_id
                }
                this.$emit('on-select', name, val)
            },
            removeFilters() {
                this.firstSliderLoad = true
                this.filter = {
                    listingType: '',
                    condition: '',
                    categoryId: ''
                }
                this.$emit('remove-filters')
            },
            categoriesFilter() {
                this.columns = []
                const categories = this.categories.slice()
                const mid = Math.ceil(categories.length / 2)
                for (let col = 0; col < 2; col++) {
                    this.columns.push(categories.slice(col * mid, (col * mid) + mid))
                }

                let mainCategory = 0
                if (this.columns.length === 2) {
                    if (this.columns[0].length > this.columns[1].length) mainCategory = this.columns[0].length
                    else mainCategory = this.columns[1].length
                }
                let max = 0
                for (const item of this.categories) {
                    if (item.children.length > max) max = item.children.length
                }
                this.categoriesColumnsHeight = (max + mainCategory) * 32
                this.categoriesColumnHeight = (max + this.categories.length) * 32
            }
        },
        mounted() {
            if (this.$route.path.includes('oglasi')) this.categoriesFilter()
            if (this.$route.query.listingType) this.filter.listingType = this.$route.query.listingType
            if (this.$route.query.condition) this.filter.condition = this.$route.query.condition
            if (this.$route.query.category_id) this.filter.categoryId = this.$route.query.category_id
            this.loadValues()
        },
        watch: {
            'slider.value'() {
                this.loadValues()
            },
            'filter.listingType'(val) {
                if (val !== '') this.$emit('on-select', 'listingType', val)
            },
            'filter.condition'(val) {
                if (val !== '') this.$emit('on-select', 'condition', val)
            },
            'categories'(val) {
                if (val && val.length > 0)  if (this.$route.path.includes('oglasi')) this.categoriesFilter()
            },
            '$route.query.listingType'(val) {
                if (val) this.filter.listingType = this.$route.query.listingType
            }
        }
    }
</script>

<style scoped>
.filters-button {
  color: black !important;
  background: rgba(231, 230, 230, 0.4) !important;
  border-color: rgba(231, 230, 230, 0.4) !important;
}
.remove-filters {
  color: white !important;
  background: rgba(231, 230, 230, 0.4) !important;
  border: none !important;
}
.filters-button:hover, .remove-filters:hover {
  box-shadow: 0 8px 25px -8px #E7E6E6 !important;
}
.top-navbar{
  position: relative;
  top: -40px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .top-navbar{
    position: relative;
    top: -25px;
  }
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: white;
}
.form-control, .input-group-text {
  border-radius: 0 !important;
  border: none !important;
  background-color: #E7E6E6;
  color: black;
  font-weight: 400;
  font-size: 17px;
}
input::placeholder {
  color: black !important;
  text-transform: uppercase;
}
input:focus, .form-control:focus + .input-group-append .input-group-text {
  background-color: white !important;
  border-color: white;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1);
}
.input-group-text {
  padding: 0.5rem;
}
.filter-content {
  background: rgba(255, 255, 255, 0.96);
  z-index: 99;
}
</style>

<style>
.vue-slider {
  height: 0.575rem !important;
}
.vue-slider-dot {
  width: 36px !important;
  height: 36px !important;
}
.vue-slider-dot-handle {
  background-color: #72A5D8 !important;
  border: 5px solid white !important;
  box-shadow: unset !important;
}
.vue-slider-dot-tooltip-top {
  display: none !important;
}
.vue-slider-rail {
  background-color: rgba(231, 230, 230, 0.4) !important;
  border-radius: 42px !important;
  border: unset !important;
  box-shadow: unset !important;
}
.vue-slider-process {
  background-color: #B9D9F2 !important;
  border: unset !important;
  box-shadow: unset !important;
}
.vue-slider:hover .vue-slider-process {
  background-color: #B9D9F2 !important;
}
.filter-collapse .card {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.filter-collapse .card-header .collapse-title {
  font-weight: 400;
  font-size: 20px !important;
  line-height: 32px !important;
  color: black !important;
}
.filter-collapse .card-header {
  padding: 0 !important;
}
.filter-collapse .card-header::after {
  display: none !important;
}
.filter-collapse .card-header[aria-expanded="true"] .collapse-title {
  font-weight: 700 !important;
}
.filter-collapse .card-body {
  padding: 0 0 0 0.1rem !important;
}
.filter-collapse .card-body a {
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #848484 !important;
}
.filter-collapse .card-body a:hover {
  font-weight: 700;
}
.filter-collapse .collapse-title:hover {
  font-weight: 700;
}
</style>